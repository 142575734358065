/* thai */
@font-face {
  font-family: 'Noto Sans Thai Looped';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0df540662d1f5040-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* latin-ext */
@font-face {
  font-family: 'Noto Sans Thai Looped';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a460634bb4bc9583-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Noto Sans Thai Looped';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f63ca3e8471e3721-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: 'Noto Sans Thai Looped';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a85458d83adbdb0b-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* latin-ext */
@font-face {
  font-family: 'Noto Sans Thai Looped';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/1a0bf18db3486647-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Noto Sans Thai Looped';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/1925f875ec1531db-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Noto Sans Thai Looped Fallback';src: local("Arial");ascent-override: 117.56%;descent-override: 32.92%;line-gap-override: 0.00%;size-adjust: 106.33%
}.__className_12297d {font-family: 'Noto Sans Thai Looped', 'Noto Sans Thai Looped Fallback';font-style: normal
}.__variable_12297d {--font-noto-sans-looped-th: 'Noto Sans Thai Looped', 'Noto Sans Thai Looped Fallback'
}

